.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::selection {
  color: white;
  background: #27ae60;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background-image-box {
  background-image: url("/src/assets/logo.svg");
  background-size: cover;
  background-position: center;
}
.slider-arrow.prev-arrow {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.slider-arrow.next-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.margin-container {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

/* Small devices (phones in landscape) */
@media (min-width: 600px) and (max-width: 899px) {
  .margin-container {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
}

/* Medium devices (tablets) */
@media (min-width: 900px) and (max-width: 1199px) {
  .margin-container {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
}
/* Color Section */
.themeColor {
  color: #27ae60 !important;
}
.themeBackground {
  background: #27ae60 !important;
}
.themeBorder {
  /* border: 1px solid #59981a !important; */
  border: 1px solid #27ae60 !important;
}
.themeLiteColor {
  color: #e7fdee !important;
}
.themeLiteBg {
  background: #e7fdee !important;
}
/* Color Section-End*/
.socialIconSize > a > img {
  height: 40px;
  width: 40px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
}
.logoFooter {
  height: 130px !important;
  width: 300px !important;
}
.navItems {
  font-size: 18px;
}

.css-tt01uh:focus-visible,
.css-tt01uh[data-focus-visible],
.css-mb8254:focus-visible,
.css-mb8254[data-focus-visible],
.css-mb8254:focus-visible,
.css-mb8254[data-focus-visible],
.css-10wjqo2:focus-visible,
.css-10wjqo2[data-focus-visible],
.css-161pkch:focus-visible,
.css-161pkch[data-focus-visible],
.css-1tm731q:focus-visible,
.css-1tm731q[data-focus-visible] {
  border-color: #27ae60 !important;
  box-shadow: 0 0 0 1px #27ae60;
}
.css-vtg3j9[aria-checked="true"],
.css-vtg3j9[data-checked] {
  background-color: #27ae60 !important;
  border-color: #27ae60 !important;
}

.css-1ydjfm6[aria-checked="true"],
.css-1ydjfm6[data-checked] {
  background: #27ae60 !important;
  border-color: #27ae60 !important;
}
.css-1ik4h6n:focus-visible,
.css-1ik4h6n[data-focus-visible] {
  box-shadow: 0 0 0 3px transparent !important;
}
/* 3024 x 1964 */
.example::-webkit-scrollbar {
  display: none;
}
.css-121ses3 {
  color: #27ae60 !important;
}
